import styled from "styled-components";

export const PortfolioBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowProjectsButton = styled.button`
  font-weight: 400;
  min-height: 47px;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: #000000;
  color: #fff;

  .more-icon {
    rotate: 228deg;
  }

  @media (max-width: 600px) {
    min-height: unset;
    font-size: 14px;
  }
`;

export const FAQMain = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  @media (min-width: 1440px) {
    /* padding-top: 80px; */
  }
  @media (max-width: 820px) {
    .HomeTwoEngineeredButton {
      left: 8rem;
    }
  }
  @media (max-width: 768px) {
    .HomeTwoEngineeredButton {
      left: 7rem;
    }
  }
  @media (max-width: 479px) {
    .front-end-heading {
    }
    .HomeTwoEngineeredButton {
      left: 0rem;
    }
  }
`;

export const FAQMainSub = styled.div`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 14px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.82fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 40px;
  display: grid;
  border-radius: 14px;
  border: 1px solid var(--border-color);

  .step__count {
    display: flex;
    width: 38px;
    height: 38px;
    background: #c8c8c8;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: all 300ms cubic-bezier(0.48, 0.01, 0.42, 1.37);
    background: #fb2979 !important;

    &.active {
      transform: scale(1.5);
      background: #4caf50;
    }
  }
  .greenLine {
    background: #fb2979;
    z-index: 2;
    position: absolute;
    content: "";
    top: 20px;
    left: 17px;
    width: 2px;
    height: calc(100% + 90px);

    &.line_height {
      height: calc(100% + 20px);
    }
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 24px;
    padding: 48px;
  }

  @media screen and (max-width: 1366px) {
    .step__count {
      left: 0px;
      width: 28px;
      height: 28px;
    }
  }
  @media screen and (max-width: 1024px) {
    .step__count {
      left: -110px;
      width: 28px;
      height: 28px;
    }
  }
  @media screen and (max-width: 991px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    border-radius: 12px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 14px;
    padding: 32px;
    .step__count {
      left: -70px;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    display: block;
  }
  @media screen and (max-width: 479px) {
    margin-bottom: 16px;
    padding: 24px;
    .step__count {
      top: -60px;
      left: -35px;
      width: 15px;
      height: 15px;
    }
    &.HomeTwoEngineeredFAQMainSub {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

export const FAQMainSubColA = styled.div`
  letter-spacing: -0.1px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;

  @media screen and (min-width: 1440px) {
    font-size: 26px;
    line-height: 42px;
    letter-spacing: -0.1px;
    margin-bottom: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 475px) {
    padding: 0;
    box-sizing: border-box;
    margin: 0;

    font-size: 14px;
    line-height: 22px;
  }
`;

export const FAQMainSubColQ = styled.div`
  padding-right: 50px;
  display: inline-block;
  position: relative;

  @media screen and (min-width: 1440px) {
    letter-spacing: -1px;
    padding-right: 120px;
    display: inline-block;
    position: relative;
  }
  @media (min-width: 991px) and (max-width: 1025px) {
    .firstIcon {
      top: 33px;
      left: 30%;
    }
    .secIcon {
      bottom: 15px;
      width: 100%;
      right: 20px !important;
    }
    .thrdIcon {
      top: 3px;
      left: -9px;
    }
  }
  @media (max-width: 820px) {
    .screen {
      right: 52% !important;
    }
  }
  @media (max-width: 600px) {
    padding-right: 0px;
    .firstIcon {
      top: 33px;
      left: 40%;
    }
    .secIcon {
      bottom: 15px;
      width: 50%;
      right: 50px !important;
    }
    .thrdIcon {
      top: -2px;
      left: -20px;
    }
  }
`;

export const FAQTitle = styled.h3`
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 28px;
  line-height: 38px;

  &.font-Oswald {
    font-family: Oswald;
    font-size: 48px;
    line-height: 54px;
  }

  @media screen and (min-width: 1440px) {
    letter-spacing: -1px;
    font-size: 36px;
    line-height: 48px;

    &.font-Oswald {
      font-size: 48px;
      line-height: 54px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 32px;
    &.font-Oswald {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @media (max-width: 475px) {
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.01px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;

    &.font-Oswald {
      font-size: 20px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 479px) {
    .box-wide__title {
      letter-spacing: 0.01px;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

export const TeamHeaderMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 200px;
  padding-bottom: 160px;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 80px;
  ${"" /* min-height: calc(100vh - 100px); */}

  &.webPage {
    min-height: unset;
    padding: 5rem 0;
  }
  &.FrontBottomt {
    padding: 7rem 0;
  }
  &.ELearningBusiness {
    padding: 5rem 0;
  }
  &.DoweMain {
    padding: 10rem 0;
  }
  &.AndroidHeaderMain {
    margin-bottom: 0px;
  }
  &.oddo {
    padding-top: 60px;
  }
  &.WooCommerce {
    padding-top: 80px;
  }
  @media (min-width: 1440px) {
    &.iosHeaderMain {
      padding-top: 115px;
    }
    &.e-commerce {
      padding-top: 115px;
    }
    &.WooCommerce {
      padding-top: 115px;
    }
    margin-bottom: 0;

    &.joomlaMain {
      padding-top: 160px;
    }
  }
  @media (max-width: 1200px) {
    &.iosHeaderMain {
      padding-top: 90px;
    }
    &.e-commerce {
      padding-top: 90px;
    }
    &.WooCommerce {
      padding-top: 90px;
    }
    &.oddo {
      padding-top: 60px;
    }
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    min-height: unset;
    /* padding-bottom: 160px; */

    &.webPage {
      padding: 5rem 0;
    }
    &.ELearningBusiness {
      padding: 1rem 0;
    }
    .shopifySub {
      font-size: 20px;
      line-height: 32px;
    }
    &.shopifyMain {
      gap: 1rem;
      /* padding-bottom: 1rem !important; */
    }
    &.iosHeaderMain {
      padding-top: 130px;
    }
    &.e-commerce {
      padding-top: 130px;
    }
    &.WooCommerce {
      padding-top: 130px;
    }
  }
  @media (max-width: 991px) {
    /* padding-bottom: 140px; */
    padding-top: 140px;
    padding-bottom: 100px;
    margin-bottom: 0px;
    &.DoweMain {
      padding: 8rem 0;
    }
    &.iosHeaderMain {
      padding-top: 100px;
    }
    &.e-commerce {
      padding-top: 100px;
    }
    &.WooCommerce {
      padding-top: 100px;
    }
  }
  @media (max-width: 820px) {
    margin-top: 0rem;
    min-height: unset;
    /* padding-bottom: 160px; */
    svg.BottomLineSvg {
      width: 70%;
    }

    span.EasierSvg {
      right: 86px !important;
      top: 27px;
      svg {
        width: 70%;
      }
    }
    span.UnderStanding {
      top: -12px !important;
      left: -11px !important;

      svg {
        width: 83%;
      }
    }
    &.ELearningBusiness {
      padding: 10rem 0;
    }
    &.TimeExpand {
      padding-bottom: 5rem;
    }
    &.shopifyMain {
      margin-top: 2rem;
    }
  }
  @media (max-width: 768px) {
    span.EasierSvg {
      right: 77px !important;
      top: 27px;
      svg {
        width: 70%;
      }
    }
  }
  @media (max-width: 479px) {
    gap: 1rem;
    padding-bottom: 100px;
    padding-top: 30px;
    margin-top: 0;
    &.pageHead {
      padding-top: 30px;
    }
    &.e-commerce {
      margin-bottom: 0rem;
      padding-bottom: 10px;
      padding-top: 50px;
    }
    &.WooCommerce {
      margin-bottom: 0rem;
      /* padding-bottom: 10px; */
      padding-top: 50px;
    }

    &.oddo {
      padding-top: 1rem !important;
    }
    &.mobileapp {
      margin-bottom: 1rem;
      padding-top: 2rem;
    }
    &.webPage {
      padding: 3rem 0;
    }
    .faqHeading {
      letter-spacing: 0.01px;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 26px;
    }
    &.FlutterFaq {
      margin-top: 0rem;
    }
    &.webPage {
      margin-bottom: 2rem !important;
    }
    &.TimeExpand {
      padding-bottom: 100px;
    }
    &.iosHeaderMain {
      padding-bottom: 100px;
      padding-top: 30px;
    }
    &.AndroidHeaderMain {
      padding-bottom: 10px;
    }
    span.EasierSvg {
      right: 4px !important;
      top: 22px;
      svg {
        width: 50%;
      }
    }
    span.UnderStanding {
      svg {
        width: 69%;
      }
    }
  }
`;